import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import type { CardType } from '@src/features/SearchResults/types'
import { capitalizeFirstLetter } from '@src/utils/helpers'

import { useGetStatsV2Query } from '../Dashboard/AnalyticsServices'
import {
  useSearchQuery,
  useOsintCheckMutation,
} from '../SearchPage/SearchServices'

const excludeKeys = (entries: [string, string][], keysToExclude: string[]) => {
  return entries.filter(([key]) => !keysToExclude.includes(key))
}

const useSearchResults = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { search, pathname } = location

  const filtersObject = useMemo(() => {
    const params = new URLSearchParams(search)
    const entries = Array.from(params.entries())
    const filters: { [key: string]: any } = Object.fromEntries(
      excludeKeys(entries, ['schema', 'query', 'categoryKey']),
    )

    if (filters.country) {
      filters.country = filters.country
        .split(',')
        .map((id: string) => parseInt(id, 10))
    }
    if (filters.citizenship) {
      filters.citizenship = filters.citizenship
        .split(',')
        .map((id: string) => parseInt(id, 10))
    }
    if (filters.address) {
      filters.address = filters.address.split(',')
    }
    if (filters.subsidiaries) {
      filters.subsidiaries = filters.subsidiaries.split(',')
    }

    return filters
  }, [search])

  const schema = useMemo(() => {
    const params = new URLSearchParams(search)
    return (params.get('schema') as 'company' | 'person') || 'company'
  }, [search])

  const query = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('query') || ''
  }, [search])

  const categoryKey = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('categoryKey')
  }, [search])

  const { data, isLoading, isFetching } = useSearchQuery(
    {
      schema: schema as 'company',
      query: query,
      filters: filtersObject,
    },
    {
      skip: !schema || !query,
    },
  )

  const skipAnalytics = !data
  const {
    data: analyticsData,
    error: analyticsError,
    isLoading: isLoadingAnalytics,
  } = useGetStatsV2Query({ query, entity: schema }, { skip: skipAnalytics })

  const hasNavigated = useRef(false)
  useEffect(() => {
    if (
      !categoryKey &&
      pathname === '/search-result' &&
      !hasNavigated.current
    ) {
      const newParams = new URLSearchParams(search)
      newParams.set('categoryKey', 'adverse_media')

      hasNavigated.current = true
      navigate(`${pathname}?${newParams.toString()}`, { replace: true })
    }
  }, [categoryKey, navigate, pathname, search])

  const selectedCategoryItem = useMemo(() => {
    if (isLoading || !data) return []

    if (categoryKey === 'beneficial_ownership_ro') {
      const openOwnershipData = data['open_ownership'] || []
      const beneficialOwnershipData = data['beneficial_ownership_ro'] || []
      return [...beneficialOwnershipData, ...openOwnershipData]
    }

    return data[categoryKey || 'adverse_media'] || []
  }, [isLoading, data, categoryKey])

  const results = data?.adverse_media?.length || 0
  const totalItemsInCategory = selectedCategoryItem?.length || 0

  const calculateLength = (responseData: any) => {
    if (!responseData) return 0
    return Object.values(responseData).reduce(
      (total: number, arr) => total + (Array.isArray(arr) ? arr.length : 0),
      0,
    )
  }

  const length = calculateLength(data)
  const lenghtPerCategories = useMemo(() => {
    if (!data) return {}
    return Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Array.isArray(value) ? value.length : 0,
      }),
      {},
    )
  }, [data])

  const title = capitalizeFirstLetter(
    `${data?.entity} check: ${data?.query} (${length})`,
  )

  const handleCategoryChange = (cardType: CardType) => {
    const newParams = new URLSearchParams(search)
    newParams.set('categoryKey', cardType)
    navigate(`${pathname}?${newParams.toString()}`, { replace: true })
  }

  const [sortOrder, setSortOrder] = useState<string>('Relevance')
  const sortedCategoryItems = useMemo(() => {
    if (!selectedCategoryItem) return []
    const sorted = [...selectedCategoryItem]

    switch (sortOrder) {
      case 'Newest':
        sorted.sort(
          (a, b) =>
            new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
        )
        break
      case 'Oldest':
        sorted.sort(
          (a, b) =>
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
        )
        break
      case 'Relevance':
      default:
        break
    }
    return sorted
  }, [sortOrder, selectedCategoryItem])

  const [selectedCards, setSelectedCards] = useState<{
    [key: string]: Set<number>
  }>({
    adverse_media: new Set(),
    basic_news: new Set(),
    litigation: new Set(),
    sanction_watchlist_pep: new Set(),
    corporate_wealth: new Set(),
    beneficial_ownership_ro: new Set(),
    open_ownership: new Set(),
  })

  const handleSelectedAllCards = (selectAll: boolean) => {
    setSelectedCards((prevSelectedCards) => ({
      ...prevSelectedCards,
      [categoryKey as string]: selectAll
        ? new Set(sortedCategoryItems.map((item: any) => item.id))
        : new Set(),
    }))
  }

  const { buffer, loadMoreItems } = useBuffer(sortedCategoryItems, categoryKey)

  const [selectAllState, setSelectAllState] = useState<{
    [key: string]: boolean
  }>({
    adverse_media: false,
    basic_news: false,
    litigation: false,
    sanction_watchlist_pep: false,
    corporate_wealth: false,
    beneficial_ownership_ro: false,
    open_ownership: false,
  })

  const handleSelectAll = (catKey: string, selectAll: boolean) => {
    setSelectedCards((prev) => ({
      ...prev,
      [catKey]: selectAll
        ? new Set(sortedCategoryItems.map((item: any) => item.id))
        : new Set(),
    }))
    setSelectAllState((prev) => ({
      ...prev,
      [catKey]: selectAll,
    }))
  }

  const getSelectAllState = (catKey: string) => {
    return selectAllState[catKey] || false
  }

  const [
    osintCheck,
    { isLoading: isOsintLoading, data: osintData, error: osintError },
  ] = useOsintCheckMutation()

  const prevOsintParamsRef = useRef({ email: null, phone: null })

  useEffect(() => {
    const { osintEmail, osintPhone } = filtersObject
    const email = (typeof osintEmail === 'string' && osintEmail.trim()) || ''
    const phone = (typeof osintPhone === 'string' && osintPhone.trim()) || ''

    const changedEmail = email && email !== prevOsintParamsRef.current.email
    const changedPhone = phone && phone !== prevOsintParamsRef.current.phone

    if ((changedEmail || changedPhone) && (email || phone)) {
      osintCheck({
        query: `pdf:${email || phone}`,
        type: email ? 'email' : 'phone',
      })
      prevOsintParamsRef.current = { email, phone }
    }
  }, [filtersObject, osintCheck])

  return {
    lenghtPerCategories,
    selectedCategoryKey: categoryKey,
    selectedCategoryItem: buffer,
    totalItemsInCategory,
    handleCategoryChange,
    schema,
    title,
    data,
    isLoading,
    isFetching,
    results,
    loadMoreItems,
    setSortOrder,
    sortOrder,
    handleSelectedAllCards,
    selectedCards,
    setSelectedCards,
    handleSelectAll,
    getSelectAllState,
    // Analytics
    analyticsData,
    analyticsError,
    isLoadingAnalytics,
    // OSINT states
    isOsintLoading,
    osintData,
    osintError,

    // filters
    filtersObject,
  }
}

export default useSearchResults

const useBuffer = (data: any[], categoryKey: any) => {
  const maxBuffer = 10
  const [buffer, setBuffer] = useState<any[]>([])
  const pointer = useRef(0)
  const hasInitialized = useRef(false)

  useEffect(() => {
    if (categoryKey && (!data || data.length === 0)) {
      setBuffer([])
    }
  }, [categoryKey, data])

  useEffect(() => {
    if (!hasInitialized.current || data.length > 0) {
      setBuffer(data.slice(0, maxBuffer))
      pointer.current = maxBuffer
      hasInitialized.current = true
    }
  }, [data])

  const loadMoreItems = () => {
    setBuffer((prevBuffer: any[]) => {
      const nextPointer = pointer.current + maxBuffer
      const newItems =
        nextPointer < data.length
          ? data.slice(pointer.current, nextPointer)
          : data.slice(pointer.current)

      pointer.current = Math.min(nextPointer, data.length)
      return [...prevBuffer, ...newItems]
    })
  }

  return { buffer, loadMoreItems }
}
