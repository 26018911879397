// File: src/features/SearchPage/SearchServices.ts
import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWith401Handling } from '@src/utils/apiBaseQuery'

interface FiltersObject {
  [key: string]: string | string[]
}

interface SearchQueryParams {
  schema: 'company' | 'person'
  query: string
  filters: FiltersObject
}

interface OsintCheckParams {
  type: 'phone' | 'email'
  query: string
}

const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: baseQueryWith401Handling,
  endpoints: (builder) => ({
    search: builder.query<any, SearchQueryParams>({
      query: (data) => ({
        url: '/search/v2',
        method: 'POST',
        body: {
          query: data.query,
          entity: data.schema,
          filters: data.filters,
        },
      }),
    }),

    getCountryList: builder.query<any, void>({
      query: () => ({
        url: '/country',
        method: 'GET',
      }),
    }),

    osintCheck: builder.mutation<Blob, OsintCheckParams>({
      query: (payload) => ({
        url: '/osint/v3',
        method: 'POST',
        body: payload,
        responseHandler: async (res) => res.blob(),
      }),
    }),
  }),
})

export const { useSearchQuery, useGetCountryListQuery, useOsintCheckMutation } =
  searchApi

export default searchApi
