import DashboardIcon from '@mui/icons-material/Dashboard'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { useState } from 'react'
import { matchPath, useNavigate } from 'react-router-dom'

// eslint-disable-next-line import/order
import Logo from '@src/common/components/Logo'

interface DrawerListProps {
  open: boolean
  handleDrawerClose?: () => void
  showHeader?: boolean
  schema?: string
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 0,
  ...theme.mixins.toolbar,
  maxHeight: '80px',
}))

import { prettyLabel } from '@src/features/SearchResults/helpers'
import useSearchResults from '@src/features/SearchResults/useSearchResults'

import RecursiveMenu from './MenuuCategory'

const SEARCH_RESULTS_PATH = '/search-result'

const DrawerList: React.FC<DrawerListProps> = ({ open, schema }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const xxlScreen = useMediaQuery(theme.breakpoints.down('xxl'))

  const {
    lenghtPerCategories,
    selectedCategoryKey,
    handleCategoryChange,
    isOsintLoading,
    osintData,

    // filters
    filtersObject,
  } = useSearchResults()

  const handleNavigation = (path: string) => {
    navigate(path)
  }

  const isSearchResultsPage =
    matchPath({ path: SEARCH_RESULTS_PATH, end: false }, location.pathname) !==
    null

  const handlePreviewOsint = () => {
    if (!osintData) return

    const objectUrl = URL.createObjectURL(osintData)
    setPdfUrl(objectUrl)
    setOpenPdfModal(true)
  }

  const handleDownloadOsint = () => {
    if (!osintData) return
    const fileUrl = URL.createObjectURL(osintData)
    const link = document.createElement('a')
    link.href = fileUrl
    link.download = 'OSINT-Result.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(fileUrl)
  }

  const handleCloseModal = () => {
    setOpenPdfModal(false)
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl)
    }
    setPdfUrl(null)
  }

  const nonEmptyFilterKeys = Object.keys(filtersObject).filter((key) => {
    const val = filtersObject[key]
    return val !== null && val !== ''
  })

  return (
    <>
      <DrawerHeader>
        <Box>
          <Logo isSideBar={true} />
        </Box>
      </DrawerHeader>
      <Divider
        sx={{
          borderColor: theme.palette.common.white,
        }}
      />

      <List>
        {[
          {
            path: '/dashboard',
            text: 'Dashboard',
            Icon: DashboardIcon,
          },
          { path: '/', text: 'Search', Icon: FindInPageIcon },
          // {
          //   // path: '/search-results',
          //   text: 'Search results',
          //   Icon: DescriptionIcon,
          // },
        ].map(({ path, text, Icon }, index) => (
          <ListItem
            key={`${index}-${path}`}
            onClick={() => path && handleNavigation(path)}
            disablePadding
            sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                color: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                  color: theme.palette.text.sidebarText,
                },
                '&:hover .MuiListItemIcon-root': {
                  color: theme.palette.text.sidebarText,
                },
                '&:hover .MuiListItemText-primary': {
                  color: theme.palette.text.sidebarText,
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'inherit',
                }}
              >
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{
                  opacity: open ? 1 : 0,
                  color: 'inherit',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {open && isSearchResultsPage && (isMobile || xxlScreen) && (
        <Box
          sx={{
            maxHeight: 'calc(100vh - 100px)',
            height: '100%',
            overflowY: 'unset',
            overflowX: 'unset',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.grey[400],
              borderRadius: '1px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: theme.palette.grey[600],
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <List>
            <RecursiveMenu
              lenghtPerCategories={lenghtPerCategories}
              selectedKey={selectedCategoryKey}
              setSelectedKey={handleCategoryChange}
              defaultOpenKey="category"
              schema={schema}
            />
          </List>

          <Box pl={2} display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
              gap={2}
              width="100%"
            >
              <Typography fontWeight={600} color="white" variant="h5">
                Preview and Download
              </Typography>

              <Button
                variant="contained"
                onClick={handlePreviewOsint}
                disabled={isOsintLoading || !osintData}
                sx={{
                  backgroundColor: 'white',
                  color: 'primary.main',
                  maxWidth: 200,
                }}
              >
                {isOsintLoading ? 'Loading OSINT...' : 'Preview OSINT'}
              </Button>
            </Box>

            {(nonEmptyFilterKeys.length > 1 ||
              !(
                nonEmptyFilterKeys.length === 1 &&
                nonEmptyFilterKeys[0] === 'advancedFilter'
              )) && (
              <Box>
                <Typography fontWeight={600} color="primary.main" variant="h5">
                  Advance filters chosen
                </Typography>
                <Box>
                  {Object.entries(filtersObject).map(([key, value]) => {
                    if (value === null || value === '') return null

                    const label = prettyLabel(key)
                    let displayValue
                    if (Array.isArray(value)) {
                      displayValue = value.join(', ')
                    } else {
                      displayValue = String(value)
                    }

                    return (
                      <Box
                        key={key}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          ml: 1,
                          mt: 1,
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          variant="body1"
                          color="textSecondary"
                          sx={{ mr: 1 }}
                        >
                          {label}:
                        </Typography>
                        <Typography color="textSecondary" variant="body1">
                          {displayValue}
                        </Typography>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            )}
            <Dialog
              fullScreen={isMobile}
              open={openPdfModal}
              onClose={handleCloseModal}
              maxWidth="md"
              fullWidth
              PaperProps={{
                sx: {
                  borderRadius: isMobile ? 0 : 3,
                },
              }}
            >
              <DialogTitle
                sx={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                }}
              >
                OSINT Preview
              </DialogTitle>
              <DialogContent dividers sx={{ p: 0 }}>
                {pdfUrl && (
                  <iframe
                    src={pdfUrl}
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                    title="OSINT PDF"
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={handleDownloadOsint}
                  disabled={isOsintLoading || !osintData}
                >
                  Download OSINT PDF
                </Button>
                <Button onClick={handleCloseModal} variant="contained">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      )}
    </>
  )
}

export default DrawerList
