import type {
  MenuItem,
  CorporateWealthProps,
} from '@src/features/SearchResults/types'

export const generateMenuItems = (data: any): MenuItem[] => {
  const getRecordsLength = (key: keyof typeof data): number => {
    const item = data[key]
    return Array.isArray(item) ? item.length : 0
  }

  return [
    {
      text: 'Categories',
      key: 'category',
      submenu: [
        {
          key: 'adverse_media',
          text: 'Adverse Media',
          records: getRecordsLength('adverse_media'),
        },
        {
          key: 'basic_news',
          text: 'News',
          records: getRecordsLength('basic_news'),
        },
        {
          key: 'corporate_wealth',
          text: 'Company Sources',
          records: getRecordsLength('corporate_wealth'),
        },
        {
          key: 'litigation',
          text: 'Litigation',
          records: getRecordsLength('litigation'),
        },
        {
          key: 'sanction_watchlist_pep',
          text: 'Sanctions & Watchlists',
          records: getRecordsLength('sanction_watchlist_pep'),
        },
        {
          key: 'beneficial_ownership_ro',
          text: 'Beneficial Ownership',
          records: getRecordsLength('beneficial_ownership_ro'),
        },
        {
          key: 'open_ownership_ro',
          text: 'UBO',
          records: getRecordsLength('open_ownership_ro'),
        },
      ],
    },
    {
      text: 'Within Results',
      key: 'within_results',
      submenu: [
        { text: 'Subcategory 2-1', key: 'subcategory2-1' },
        { text: 'Subcategory 2-2', key: 'subcategory2-2' },
      ],
    },
    {
      key: 'date_range',
      text: 'Date Range',
      submenu: [
        { text: 'Subcategory 2-1', key: 'subcategory2-1' },
        { text: 'Subcategory 2-2', key: 'subcategory2-2' },
      ],
    },
    {
      key: 'company',
      text: 'Company',
      submenu: [
        { text: 'Subcategory 2-1', key: 'subcategory2-1' },
        { text: 'Subcategory 2-2', key: 'subcategory2-2' },
      ],
    },
    {
      key: 'source_type',
      text: 'Source Type',
      submenu: [
        { text: 'Subcategory 2-1', key: 'subcategory2-1' },
        { text: 'Subcategory 2-2', key: 'subcategory2-2' },
      ],
    },
    {
      key: 'source',
      text: 'Source',
      submenu: [
        { text: 'Subcategory 2-1', key: 'subcategory2-1' },
        { text: 'Subcategory 2-2', key: 'subcategory2-2' },
      ],
    },
    {
      key: 'language',
      text: 'Language',
      submenu: [
        { text: 'Subcategory 2-1', key: 'subcategory2-1' },
        { text: 'Subcategory 2-2', key: 'subcategory2-2' },
      ],
    },
  ]
}

export const extractDate = (text: string): string | null => {
  const dateRegex =
    /\b(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{1,2}, \d{4}\b/
  const match = text?.match(dateRegex)
  return match ? match[0] : null
}

export const formatDate = (
  dateString: string | Date,
  style: 'short' | 'long',
) => {
  if (!dateString) {
    return 'No Date'
  }

  const date = new Date(dateString)

  if (isNaN(date.getTime())) {
    return 'N/A'
  }

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  if (style === 'long') {
    return `${String(day).padStart(2, '0')}.${String(month + 1).padStart(2, '0')}.${year}`
  } else {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: '2-digit',
    }
    return new Intl.DateTimeFormat('en-US', options).format(date)
  }
}

export const getJurisdictionCountries = (
  jurisdiction_code: CorporateWealthProps['jurisdiction_code'],
) => {
  if (!jurisdiction_code) return ''
  const countries = Object.values(jurisdiction_code).map((item) => item.country)
  return countries.join(', ')
}

export function prettyLabel(key: string) {
  const withSpaces = key.replace(/([A-Z])/g, ' $1')
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1)
}
